body {
  margin: 0;
  overflow-x: hidden;
}
main {
  background: #fff;
  font-family: "Gilroy";
  h1,
  h2,
  h3,
  p {
    color: #00384a;
    z-index: 10;
    text-align: center;
  }
  &.home h1,
  .work h1 {
    font-size: clamp(60px, 10vw, 100px);
    margin: 0 0 38px 0;
    background: #ffffffa3;
    @media (min-width: 767px) {
      white-space: nowrap;
    }
  }
  h2 {
    font-size: clamp(60px, 12vw, 145px);
    text-align: center;
    color: #fff;
    margin: 0;
  }
  h6 {
    font-family: GilroyRegular;
    font-size: clamp(14px, 3vw, 30px);
    font-weight: 500;
    text-align: center;
    color: #00384a;
    margin: 0;
  }
  h3 {
    font-size: clamp(35px, 5vw, 45px);
    margin: 0;
  }
  &.home h3 {
    background: #ffffffa3;
    display: inline;
  }
  @media (max-width: 767px) {
    max-width: 100vw;
  }
}
nav {
  position: fixed;
  top: 30px;
  left: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 12;
  width: 70px;
  .top,
  .bottom {
    display: flex;
    justify-content: center;
    height: 70px;
  }
  .top {
    align-items: center;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    svg {
      cursor: pointer;
    }
  }
  .bottom {
    gap: 5px;
    flex-direction: column;
    background-color: #00384a;
    border-radius: 0 0 8px 8px;
    padding: 0 20px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      height: 3px;
      background: #fff;
      transition: 0.35s ease-in-out;
      &:nth-child(1) {
        width: 68%;
        margin-left: auto;
      }
      &:nth-child(2) {
        width: 100%;
        margin: 0 auto;
      }
      &:nth-child(3) {
        width: 68%;
        margin-right: auto;
      }
    }
    &:hover {
      span:nth-child(1) {
        transform: translateX(-42%);
      }
      span:nth-child(3) {
        transform: translateX(42%);
      }
    }
    &.open {
      span {
        &:nth-child(1) {
          width: 28px;
          transform: rotate(45deg);
          transform-origin: 24% 136%;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          width: 28px;
          transform: rotate(315deg);
          transform-origin: 44% 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    width: 50px;
    top: 16px;
    left: 16px;
    .top,
    .bottom {
      height: 50px;
    }
    .bottom {
      padding: 0 12px;
      span {
        height: 2px;
      }
      &.open {
        span:nth-child(1),
        span:nth-child(3) {
          width: 95%;
        }
      }
    }
  }
}

.nav-menu {
  position: fixed;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(90px);
  z-index: 11;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 15px;
  transition: 0.5s ease;
  transition-timing-function: ease-out;
  &.close {
    visibility: hidden;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      color: #00384a;
      h4,
      h5 {
        line-height: 150%;
        margin: 0;
      }
      h4 {
        font-size: 40px;
      }
      h5 {
        font-size: 24px;
      }
      a {
        position: relative;
        text-decoration: none;
        color: #00384a;
        &::before {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          inset: 0 0 0 0;
          background: lightblue;
          z-index: -1;
          transition: transform 0.5s ease;
          transform: scaleX(0);
          transform-origin: bottom right;
        }
        &:hover::before {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
      &.title {
        font-size: 14px;
      }
      & + li {
        margin-top: 10px;
      }
    }
  }
  .science {
    margin: 30px 0;
  }
  @media (min-width: 767px) {
    top: 15px;
    left: 15px;
    padding: 20vh 15vw 0;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    li.social {
      margin-top: 120px;
    }
  }
  @media (max-width: 767px) {
    top: 5px;
    left: 5px;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(100vw - 10px);
    height: calc(100vh - 10px);
    ul + div {
      margin-top: 8vh;
    }
  }
}

main:not(.form) .hero {
  cursor: none;
}

.hero {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #fff;
  animation: gradients 20s infinite alternate;
  background-repeat: no-repeat;
  background-size: cover;
  span {
    white-space: nowrap;
    &:nth-child(odd) {
      animation: scroll-left 2s linear infinite alternate;
    }
    &:nth-child(even) {
      animation: scroll-right 2s linear infinite alternate;
    }
  }
  .marquee {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 34px;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
  .overlay {
    position: absolute;
    z-index: 9;
    background: #fff;
    width: 100%;
    height: 100%;
    mix-blend-mode: screen;
    .window {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 400px;
      height: 400px;
      box-shadow: inset 0px 0px 40px 50px #fff;
      border-radius: 50%;
      clip-path: circle(49% at 50% 50%);
      transition-duration: 500ms;
      transition-timing-function: ease-out;
      position: absolute;
      transform: translateZ(0);
      background: #000;
      @media (max-width: 767px) {
        width: 300px;
        height: 300px;
        top: -150px;
        left: -150px;
      }
    }
  }
  .title {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @media (max-width: 767px) {
      p {
        font-size: 24px;
        width: 200px;
        margin: auto;
      }
    }
  }
}

.sections {
  overflow: hidden;
}

.about {
  background: url("https://assets-springhire.gumlet.io/about-vector.svg") no-repeat;
  background-size: cover;
  p {
    font-family: GilroyRegular;
    max-width: 800px;
    margin: 50px auto 0;
    line-height: 160%;
    padding: 0 50px;
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .top-blur,
    .center-blur {
      animation: grow 5s ease infinite alternate;
    }
    .top-blur {
      position: absolute;
      top: -450px;
      left: -450px;
      width: 900px;
      height: 900px;
      background-color: #e4c3c9;
      border-radius: 50%;
      z-index: -1;
    }
    .center-blur {
      position: absolute;
      top: 50%;
      right: -350px;
      width: 500px;
      height: 500px;
      background-color: #e7cfde;
      border-radius: 50%;
      z-index: -1;
    }
    h1 {
      font-size: clamp(45px, 10vw, 65px);
      max-width: 636px;
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    section + section {
      margin-top: 200px;
    }
    h1 {
      padding: 0 55px;
    }
    p {
      padding: 0 55px;
      margin: 20px auto 0;
    }
    .banner {
      padding-top: 136px;
    }
    .top-blur {
      transform: scale(0.5);
      filter: blur(85px);
    }
    .center-blur {
      transform: scale(0.75);
      filter: blur(85px);
    }
    footer {
      margin-top: 250px;
    }
  }
  @media (min-width: 767px) {
    .banner {
      min-height: 100vh;
      overflow: hidden;
    }
    .top-blur,
    .center-blur {
      filter: blur(55px);
    }
    .services {
      height: 100vh;
    }
    footer {
      margin-top: 480px;
      h2 {
        margin-bottom: 250px;
      }
    }
    section + section {
      margin-top: 200px;
    }
  }
}

.form {
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form {
    max-width: 700px;
    margin: auto;
    h3 {
      color: #fff;
      margin-bottom: 60px;
    }
    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 16px;
      background: none;
      border: none;
      outline: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      font-family: Gilroy;
      color: #fff;
      padding: 5px 0;
      width: 100%;
      resize: none;
      &::placeholder {
        color: #ddd;
      }
    }
    > div + div {
      margin-top: 30px;
    }
    a,
    button {
      font-size: 24px;
      background: none;
      border: none;
      color: #fff;
      font-family: Gilroy;
      border-bottom: 1px solid #fff;
      margin-top: 70px;
      padding: 0 0 2px;
      text-decoration: none;
      cursor: pointer;
      &.center {
        margin: 40px auto 0;
      }
      &:hover {
        border-bottom: 1px solid #00384a;
      }
    }
  }
  @media (max-width: 767px) {
    form {
      padding: 0 25px;
      text-align: center;
      h3 {
        padding: 0 50px;
      }
      a {
        margin-top: 30px;
      }
    }
  }
}

.sections-wrapper {
  &.home {
    background: linear-gradient(
      180.18deg,
      rgba(255, 255, 255, 0.35) 2.99%,
      rgba(224, 191, 65, 0.35) 16.12%,
      rgba(238, 0, 24, 0.35) 27.44%,
      rgba(49, 12, 115, 0.35) 42.27%,
      rgba(116, 107, 244, 0.35) 54.06%,
      rgba(117, 202, 155, 0.35) 66.15%,
      rgba(93, 201, 198, 0.35) 83.06%,
      rgba(255, 255, 255, 0.35) 96.58%
    );
  }
  &.work {
    background: linear-gradient(
      180.18deg,
      rgba(255, 255, 255, 0.35) 2.99%,
      rgba(93, 201, 198, 0.35) 16.12%,
      rgba(117, 202, 155, 0.35) 27.44%,
      rgba(116, 107, 244, 0.35) 42.27%,
      rgba(49, 12, 115, 0.35) 54.06%,
      rgba(238, 0, 24, 0.35) 66.15%,
      rgba(224, 191, 65, 0.35) 83.06%,
      rgba(255, 255, 255, 0.35) 96.58%
    );
    .services {
      height: 100vh;
      h3 {
        padding: 0 20px;
      }
    }
  }
  background-size: 100% 100%;
  .sections-inner {
    background: url("https://assets-springhire.gumlet.io/bg-vector.svg") no-repeat;
    background-size: cover;
    height: 100%;
    section {
      &:not(.burst):not(.range) img {
        position: absolute;
      }
      h2 {
        position: sticky;
        top: 0;
        z-index: 0;
      }
    }
    @media (min-width: 767px) {
      section:not(&.toro):not(&.range) {
        margin-inline: auto;
      }
      section {
        margin-block: 450px;
        &.range {
          margin-bottom: 0;
          padding-bottom: 450px;
        }
      }
    }
    @media (max-width: 767px) {
      section {
        margin-block: 150px;
        h2 {
          padding: 0 20px;
        }
      }
    }
  }
  section {
    &:not(.burst):not(.range) .flex {
      max-width: 1000px;
      margin: auto;
    }
    .flex {
      display: flex;
      .list {
        z-index: 1;
        ul {
          list-style: none;
          padding: 0;
          li {
            font-size: 24px;
            line-height: 36px;
            color: #00384a;
            &.title {
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }
    }
    .images {
      position: relative;
    }
    @media (min-width: 1100px) {
      min-height: 100vh;
      .flex {
        &.reversed {
          flex-direction: row-reverse;
          ul {
            text-align: right;
          }
        }
        .list {
          padding-top: 200px;
          ul + ul {
            margin-top: 65px;
          }
          li + li {
            margin-top: 5px;
          }
        }
      }
      &.burst .flex {
        .images {
          margin-left: auto;
        }
        .list {
          margin-left: calc((100% - 1000px) / 2);
        }
      }
      &.range .flex {
        .images {
          margin-right: auto;
        }
        .list {
          margin-right: calc((100% - 1000px) / 2);
        }
      }
    }
    @media (max-width: 1100px) {
      max-width: 100%;
      :is(.flex, .flex.reversed) {
        align-items: center;
        flex-direction: column-reverse;
      }
      .flex .list {
        max-width: 400px;
        width: calc(100% - 110px);
        display: flex;
        justify-content: space-between;
        ul li {
          font-size: 18px;
        }
      }
      &.porsche .flex .list {
        margin-top: 94vw;
      }
      &.toro .flex .list {
        margin-top: 78vw;
      }
      &.dance .flex .list {
        margin-top: 85vw;
      }
      &.handstand .flex .list {
        margin-top: 65vw;
      }
      .images {
        width: 100%;
      }
    }
  }
}

.services,
.team {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .list {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 60px auto 0;
    font-size: clamp(14px, 2vw, 18px);
    font-weight: bold;
    line-height: 22px;
    color: #00384a;
    gap: 40px;
    svg {
      margin-bottom: 20px;
      height: 65px;
    }
  }
  @media (min-width: 767px) {
    p + .list {
      margin-top: 90px;
    }
    .list {
      flex-wrap: wrap;
      max-width: 1000px;
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .list {
      svg {
        height: 7vh;
        margin-bottom: 10px;
      }
    }
    > div {
      flex-direction: column;
    }
  }
  @media (max-width: 1024px) {
    .list {
      max-width: 400px;
    }
  }
}

.team {
  .name {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  .role {
    font-size: 16px;
    line-height: 19px;
    font-family: GilroyLight;
  }
  .more {
    margin-top: 100px;
    font-size: clamp(30px, 5vw, 36px);
  }
  @media (max-width: 767px) {
    .more {
      margin-top: 30px;
    }
  }
  @media (max-width: 1024px) {
    .list {
      max-width: 600px;
    }
  }
}

.science {
  display: flex;
  align-items: center;
  margin-top: 36px;
  span {
    font-size: 10px;
    font-family: GilroyRegular;
    color: #00384a;
  }
}
.dash {
  width: 30px;
  margin: 0 10px;
  border-bottom: 1px solid #00384a4d;
}

footer {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: initial;
    gap: 50px;
    margin: 0 auto;
    padding: 100px 0;
    width: 90%;
  }
  a {
    text-decoration: none;
    &.blue {
      font-size: clamp(45px, 12vw, 100px);
      color: #00384a;
      line-height: 150%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 5px;
        background-color: #00384a;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      }
      &:hover::before {
        left: 0;
        right: auto;
        width: 100%;
      }
    }
    &:not(.blue):hover {
      color: #2e99bb;
      path {
        fill: #2e99bb;
      }
    }
  }
  h6,
  li {
    font-family: GilroyRegular;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 16px;
      & + li {
        margin-top: 15px;
      }
      a {
        color: #00384a;
      }
    }
  }
  @media (max-width: 767px) {
    .row {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  mix-blend-mode: multiply;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    transform: translateX(-100px);
  }
}

@keyframes gradients {
  0% {
    background-image: url(https://assets-springhire.gumlet.io/gradients/1.svg);
  }
  25% {
    background-image: url(https://assets-springhire.gumlet.io/gradients/2.svg);
  }
  50% {
    background-image: url(https://assets-springhire.gumlet.io/gradients/3.svg);
  }
  100% {
    background-image: url(https://assets-springhire.gumlet.io/gradients/4.svg);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-50px);
  }
}
@keyframes scroll-right {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(10px);
  }
}
