.porsche .images {
  img:nth-child(1) {
    top: 150px;
    left: 50px;
    animation: sway 10s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  img:nth-child(2) {
    top: 0;
    left: 450px;
    animation: sway 12s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  @media (max-width: 1100px) {
    img:nth-child(1) {
      top: 18vmin;
      left: 8%;
      width: 65vw;
    }
    img:nth-child(2) {
      left: 50%;
      width: 40vw;
    }
  }
}
.toro .images {
  img:nth-child(1) {
    top: 0;
    right: 100px;
    animation: sway 10s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  img:nth-child(2) {
    top: 150px;
    right: 30px;
    animation: sway 5s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  @media (max-width: 1100px) {
    img:nth-child(1) {
      right: 20%;
      width: 90vw;
    }
    img:nth-child(2) {
      top: 18vmin;
      right: 12%;
      width: 35vw;
    }
  }
}
.burst .images {
  overflow: hidden;
  img {
    max-width: 100vw;
    animation: sway 10s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    @media (max-width: 1100px) {
      max-width: 130vw;
    }
  }
}
.dance .images {
  img:nth-child(1) {
    top: 0;
    right: 50px;
    animation: sway 7s infinite ease;
  }
  img:nth-child(2) {
    top: 250px;
    right: 360px;
    z-index: 1;
    animation: sway 13s infinite ease;
  }
  img:nth-child(3) {
    top: 0;
    right: 560px;
    animation: sway 15s infinite ease;
  }
  @media (max-width: 1100px) {
    img:nth-child(1) {
      left: 4%;
      width: 40vw;
    }
    img:nth-child(2) {
      top: 28vmin;
      left: 22%;
      width: 40vw;
    }
    img:nth-child(3) {
      left: 52%;
      width: 40vw;
    }
  }
}
.handstand .images {
  img:nth-child(1) {
    top: 0;
    left: 0;
    animation: sway 5s infinite ease;
  }
  img:nth-child(2) {
    top: 0;
    left: 255px;
    animation: sway 10s infinite ease;
  }
  img:nth-child(3) {
    top: 0;
    left: 585px;
    animation: sway 15s infinite ease;
  }
  @media (max-width: 1100px) {
    img:nth-child(1) {
      left: 4%;
      width: 36vw;
    }
    img:nth-child(2) {
      left: 28%;
      width: 40vw;
    }
    img:nth-child(3) {
      left: 58%;
      width: 36vw;
    }
  }
}
.range .images {
  overflow: hidden;
  img {
    max-width: 70vw;
    animation: sway 10s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    @media (max-width: 1100px) {
      max-width: 100vw;
    }
  }
}
@keyframes sway {
  25% {
    transform: translate3d(10px, 10px, 10px);
  }
  50% {
    transform: translate3d(20px, 5px, 30px) rotate3d(1, 2, 5, 1deg);
  }
  70% {
    transform: translate3d(5px, 10px, 30px);
  }
}
