code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.sh-container {
	width: calc(100% - 160px);
	margin: auto;
}
@media only screen and (max-width: 995px) {
	.sh-container {
		width: 100%;
		padding: 0 25px 15px 30px;
	}
}
